import React from "react";
import { Col, Row } from "react-bootstrap";
import cg from "../../Assets/cg.png";
import uni from "../../Assets/uniswap.svg";
import dex from "../../Assets/dextools2.svg";
import eth from "../../Assets/etherscan.svg";
import moontok from "../../Assets/moontok.png";
import {

  SiCoinmarketcap,
  SiEthereum,
} from "react-icons/si";
import { TbBrandCakephp, TbBrandGolang } from "react-icons/tb";
import { FaPoo } from "react-icons/fa";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiEthereum />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={uni} alt="about" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={eth} alt="about" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={dex} alt="about" />
      </Col>
      {/* <Col xs={4} md={2} className="tech-icons">
        <img src={moontok} alt="about" />
      </Col> */}
      <Col xs={4} md={2} className="tech-icons">
        <SiCoinmarketcap />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={cg} alt="about" />
      </Col>
    </Row>
  );
}

export default Techstack;
