import React from "react";
import { ListGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
      </Card.Body>
      <ListGroup variant="flush" className="roadList">
        <ListGroup.Item>👉{props.line1}</ListGroup.Item>
        {/* <ListGroup.Item>👉{props.line2}</ListGroup.Item>
        <ListGroup.Item>👉{props.line3}</ListGroup.Item>
        <ListGroup.Item>👉{props.line4}</ListGroup.Item>
        <ListGroup.Item>👉{props.line5}</ListGroup.Item> */}
      </ListGroup>
    </Card>
  );
}
export default ProjectCards;
