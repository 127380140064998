export const telegram = "https://t.me/chameleontalk"
export const twitter = "https://x.com/chameleontalkb"
export const poo = "https://poocoin.app/tokens/0xa69685160f6aBeaFFE44D63d23D55fb8e9464211"
export const pankcake = "https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0xa69685160f6aBeaFFE44D63d23D55fb8e9464211"


// echo "# pepetok" >> README.md
// git init
// git add README.md
// git commit -m "first commit"
// git branch -M main
// git remote add origin https://github.com/m0nithin/pepetok.git
// git push -u origin main