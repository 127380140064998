import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {/* <span className="purple">THIS IS FIRE : </span> */}
            <br />
            Welcome to ChameleonTalk – where text transforms into a symphony of voices for every purpose. Unleash the power of personalized voice-changing, crafting aural experiences tailored to your needs. Elevate your projects with the versatility of ChameleonTalk, your ultimate voice-altering virtuoso.           <br />
          </p>
          {/* <ul>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              ROAD MAP <strong className="purple"> </strong>
            </h1>
            <li className="about-activity">
              <ImPointRight /> 🔥 BUY
            </li>
            <li className="about-activity">
              <ImPointRight /> 🔥 HOLD
            </li>
            <li className="about-activity">
              <ImPointRight /> 🔥 STAY ON FIRE
            </li>
          </ul> */}

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "JOIN THE FUTURE OF AI"{" "}
          </p> */}
          <footer className="blockquote-footer"> ChameleonTalk </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;


