import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/Soumyajit_Behera-BIT_MESRA.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import r1 from "../../Assets/main.png";
import r2 from "../../Assets/main.png";
import { pankcake } from "../../links";
import { ImPointRight } from "react-icons/im";

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="about-section">
        <Particle />
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px", alignItems: "center" }}>
            <Col>
              <img src={r1} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY NOW
                </a>
              </button>
            </Col>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "50px",
              }}
            >
              <h1 className="project-heading">
                <strong className="purple"> </strong> ChameleonTalk
              </h1>
              <p style={{ color: "white" }}>
                HOW TO USE BOT ?
              </p>

              <h1 className="project-heading2">
                <strong className="purple">There is 5 voices</strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />. Every /name has his own voice
                <strong className="purple"> </strong>
              </h1>
              <h1 className="project-heading2">
                How to Different <strong className="purple"> VOICES </strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />Use name and write your text. It will do it voice

                <strong className="purple"> </strong>
              </h1>
              <h1 className="project-heading2">
                Here <strong className="purple"> Examples</strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />/sophia 'your text'

              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />/oliver 'your text' -

              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />/isabella 'your text'
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />/amelia 'your text'

              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />/elijah 'your text'
              </h1>
            </Col>
            <Col>
              <img src={r2} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY NOW
                </a>
              </button>
            </Col>



          </Row>
          {/* <h1 className="project-heading">
            All <strong className="purple"> Listing</strong>
          </h1> */}

          {/* <Techstack /> */}

          {/* <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github /> */}
        </Container>
      </Container>
      <Container fluid className="about-section">
        <h1 className="project-heading">
          <strong className="purple">  CHART</strong>
        </h1>
        <iframe src="https://dexscreener.com/bsc/0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae?embed=1&amp;theme=dark&amp;trades=0&amp;info=0" className="charts"></iframe>
      </Container>
    </div>
  );
}

export default ResumeNew;
