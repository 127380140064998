import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiOutlineTwitter,
  AiOutlineSwap,
  AiFillMail,
} from "react-icons/ai";
import { FaChartArea, FaPoo, FaTelegramPlane, FaTwitch } from "react-icons/fa";
import { pankcake, poo, telegram, twitter } from "../links";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by ChameleonTalk </h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © ChameleonTalk </h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href={telegram}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegramPlane />
              </a>
            </li>
            <li className="social-icons">
              <a
                href={twitter}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href={poo}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaChartArea />
              </a>
            </li>
            <li className="social-icons">
              <a
                href={pankcake}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineSwap />
              </a>
            </li>
            <li className="social-icons">
              <a
                // href="mailto:crypto@memexbot.com"

                style={{ color: "white" }}
                target="_blank"
                rel="noreferrer"
                className="noopener noreferrer"
              >
                <AiFillMail />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
