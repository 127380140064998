import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/pepe2.jpg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
// import Name from "../../Assets/name.svg";

import {
  AiOutlineTwitter,
  AiOutlineSwap,
  AiFillMail,
} from "react-icons/ai";
import { FaChartArea, FaTelegramPlane } from "react-icons/fa";

import { pankcake, poo, telegram, twitter } from "../../links";


function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>

            <Col md={6} className="home-header">
              {/* <h1 style={{ paddingBottom: 15 }} className="heading">
                PAPA SMURF{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1> */}
              {/* <div className="heading-name">
                <img src="/name.svg" />
              </div> */}
              <h1 className="heading-name">

                <strong className="main-name">ChameleonTalk: Your voice-altering virtuoso. Text to speech, any voice, any task. Elevate projects with personalized audio experiences.</strong>
              </h1>


              {/* <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div> */}

              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href={telegram}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaTelegramPlane />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href={twitter}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <AiOutlineTwitter />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href={poo}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaChartArea />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href={pankcake}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiOutlineSwap />
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src='/main.png'
                alt="home pic"
                className="img-fluid homeLogo"
                style={{ maxHeight: "450px", borderRadius: "20%", }}
              />
            </Col>

          </Row>
        </Container>
      </Container>
      {/* <Home2 /> */}
    </section>
  );
}

export default Home;


